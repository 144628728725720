<template>
  <div>
    <div class="home">
      <div class="top">
        <div class="topMain">
          <div class="title">金箱云仓</div>
          <div class="line"></div>
          <div class="introduce">科技引领供应链 | 金融赋能生态圈</div>
          <div class="eng">
            Technology leads the supply chain and finance enables the
            ecosystem！
          </div>
          <div class="eng">
            How we look at the world,the world also looks at us, so there is no
            reason not to work hard!
          </div>
          <div class="btn" @click="goPlatForm">LEARN MORE</div>
        </div>
      </div>
      <div class="homeMain">
        <div class="bottom">
          <div class="first">
            <div class="firstMain">
              <div>数字化方案设计和技术支撑</div>
              <div></div>
              <div>
                为客户提供数字化创新和转型相关的咨询培训、方案设计、赋能等
              </div>
            </div>
            <img src="../../assets/main/home/first.webp" alt="" />
            <div class="box"></div>
          </div>
          <div class="second">
            <div
              class="firstMain"
              :style="{
                'padding-left': '340px',
              }"
            >
              <div>平台和产品服务</div>
              <div></div>
              <div>向客户提供特有的供应链平台和数字化交易技术等</div>
            </div>
            <img src="../../assets/main/home/second.webp" alt="" />
            <div class="box"></div>
          </div>
          <div class="first">
            <div class="firstMain">
              <div>公司简介</div>
              <div></div>
              <div>
                金箱科技是一家专注产业数字化创新的方案商和服务提供商，在供应链协同和数字化交易方面有领先的技术能力和服务能力。公司围绕“产业协同、数字创新”，面向集团产业群、产业生态，提供数字协同及交易平台，提供技术服务
              </div>
            </div>
            <img src="../../assets/main/home/third.webp" alt="" />
            <div class="box"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  mounted() {},

  methods: {
    goPlatForm() {
      this.$router.push("/service/saas");
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100vw;
  height: 1880px;
  overflow: hidden;
  .top {
    width: 100%;
    height: 600px;
    overflow: hidden;
    background: #fff url("../../assets/main/home/homeBg.png") no-repeat;
    background-position: 100%;
    .topMain {
      width: 1200px;
      margin: 0 auto;
      margin-top: 176px;
      .title {
        width: 129px;
        height: 32px;
        line-height: 32px;
        font-size: 32px;
        color: rgba(234, 144, 82, 1);
        font-weight: bolder;
      }
      .line {
        width: 665px;
        height: 0;
        border-top: 2px solid rgba(51, 51, 51, 1);
        margin-top: 40px;
      }
      .introduce {
        width: 400px;
        height: 24px;
        color: rgba(102, 102, 102, 1);
        font-size: 24px;
        margin-top: 40px;
      }
      .eng {
        width: 703px;
        height: 20px;
        color: rgba(153, 153, 153, 1);
        font-size: 16px;
        margin-top: 10px;
      }
      .btn {
        --bg: rgba(234, 144, 82, 1);
        transition: all 0.3s;
        width: 134px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        font-size: 11px;
        font-weight: bold;
        border-radius: 6px;
        background: var(--bg);
        margin-top: 20px;
        cursor: pointer;
      }
      .btn:hover {
        background: rgba(245, 166, 117, 1);
      }
    }
  }
  .homeMain {
    width: 1200px;
    height: 100%;
    margin: 0 auto;

    .bottom {
      width: 100%;
      height: 1280px;
      .first {
        width: 1200px;
        height: 360px;
        margin-top: 40px;
        position: relative;
        .firstMain {
          width: 970px;
          height: 100%;
          padding: 100px 0 0 110px;
          box-sizing: border-box;
          background: rgba(245, 245, 245, 1);
          div:nth-child(1) {
            width: 288px;
            height: 24px;
            line-height: 24px;
            font-size: 24px;
            font-weight: bold;
            color: rgba(51, 51, 51, 1);
          }
          div:nth-child(2) {
            width: 520px;
            height: 0;
            border-top: 1px solid rgba(102, 102, 102, 1);
            margin-top: 40px;
          }
          div:nth-child(3) {
            width: 512px;
            height: 36px;
            color: rgba(102, 102, 102, 1);
            font-size: 14px;
            margin-top: 20px;
          }
        }
        img {
          width: 440px;
          height: 240px;
          position: absolute;
          top: 60px;
          left: 740px;
          z-index: 999;
        }
        .box {
          width: 230px;
          height: 260px;
          background: rgba(234, 144, 82, 1);
          position: absolute;
          top: 60px;
          right: 0px;
        }
      }
      .second {
        @extend .first;
        margin-left: 229px;
        img {
          left: -210px;
        }
        .box {
          left: -230px;
        }
      }
    }
  }
}
</style>
